import React, { Component } from 'react';
import initializeStore from '../store';

/* eslint-disable-next-line */
const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';
const IS_SERVER = typeof window === 'undefined';

function getOrCreateReduxStore(initialState) {
  if (IS_SERVER) {
    return initializeStore(initialState);
  }

  /* eslint-disable-next-line */
  if (!window[__NEXT_REDUX_STORE__]) {
    /* eslint-disable-next-line */
    window[__NEXT_REDUX_STORE__] = initializeStore(initialState);
  }
  /* eslint-disable-next-line */
  return window[__NEXT_REDUX_STORE__];
}

export default App => class AppWithRedux extends Component {
  static async getInitialProps(appContext) {
    const reduxStore = getOrCreateReduxStore();

    /* eslint-disable-next-line no-param-reassign */
    appContext.ctx.reduxStore = reduxStore;

    let appProps = {};
    if (typeof App.getInitialProps === 'function') {
      appProps = await App.getInitialProps(appContext);
    }

    return {
      ...appProps,
      initialReduxState: reduxStore.getState(),
    };
  }

  /* eslint-disable-next-line react/destructuring-assignment, react/prop-types */
  reduxStore = getOrCreateReduxStore(this.props.initialReduxState);

  render() {
    /* eslint-disable-next-line react/jsx-filename-extension */
    return <App {...this.props} reduxStore={this.reduxStore} />;
  }
};
