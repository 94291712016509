/* eslint-disable no-restricted-syntax */
import fetch from 'isomorphic-unfetch';
import actionCreatorFactory from '../utils/action-creator-factory';

import { BACKEND_ENDPOINT } from '../../shared/config';

// action types
export const GET_CHOSEN_FORM_RESPONSE_SUCCESS = 'GET_CHOSEN_FORM_RESPONSE_SUCCESS';
export const GET_CHOSEN_FORM_RESPONSE_ERROR = 'GET_CHOSEN_FORM_RESPONSE_ERROR';

export const GET_THEME_RESPONSES_BY_FORM_SUCCESS = 'GET_THEME_RESPONSES_BY_FORM_SUCCESS';
export const GET_THEME_RESPONSES_BY_FORM_ERROR = 'GET_THEME_RESPONSES_BY_FORM_ERROR';

export const GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_SUCCESS = 'GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_SUCCESS';
export const GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_ERROR = 'GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_ERROR';


// action creators
export const getChosenFormResponseSuccess = actionCreatorFactory(GET_CHOSEN_FORM_RESPONSE_SUCCESS, 'formResponse');
export const getChosenFormResponseError = actionCreatorFactory(GET_CHOSEN_FORM_RESPONSE_ERROR);

export const getThemeResponsesByFormSuccess = actionCreatorFactory(GET_THEME_RESPONSES_BY_FORM_SUCCESS, 'formResponses');
export const getThemeResponsesByFormError = actionCreatorFactory(GET_THEME_RESPONSES_BY_FORM_ERROR);

export const getTransformedThemeResponsesByFormSuccess = actionCreatorFactory(GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_SUCCESS, 'formResponses');
export const getTransformedThemeResponsesByFormError = actionCreatorFactory(GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_ERROR);


// action functions
export function getChosenFormResponse(id) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/${id}/answers`);
      const formResponse = await response.json();
      dispatch(getChosenFormResponseSuccess(formResponse));
    } catch (err) {
      dispatch(getChosenFormResponseError());
    }
  };
}

export function getThemeResponsesByForm(formNames, theme) {
  return async (dispatch) => {
    try {
      let responses = await Promise.allSettled(formNames.map(name => fetch(`${BACKEND_ENDPOINT}/api/form/${name}/${theme}/accepted-responses`)));
      responses = await Promise.allSettled(responses.map((response) => {
        if (response.value.status === 200) {
          return response.value.json();
        }
        return [];
      }));
      let formResponses = [];
      // Aggregates different form response types into formResponses
      responses.forEach((response) => { formResponses = formResponses.concat(response.value); });
      dispatch(getThemeResponsesByFormSuccess(formResponses));
    } catch (err) {
      dispatch(getThemeResponsesByFormError());
    }
  };
}

export function getTransformedThemeResponsesByForm(formNames, theme) {
  return async (dispatch) => {
    try {
      let responses = await Promise.allSettled(formNames.map(name => fetch(`${BACKEND_ENDPOINT}/api/form/${name}/${theme}/transformed-accepted-responses`)));
      responses = await Promise.allSettled(responses.map((response) => {
        if (response.value.status === 200) {
          return response.value.json();
        }
        return [];
      }));
      const formResponses = {};
      // Aggregates different form response types into formResponses
      responses.forEach((response) => {
        const { value } = response;
        for (const key of Object.keys(value)) {
          if (!(key in formResponses)) {
            formResponses[key] = value[key];
          } else {
            // Assuming that there is no such story that is
            // both a difference future and voice otherwise
            // we risk duplicate stories
            formResponses[key] = formResponses[key].concat(value[key]);
          }
        }
      });
      dispatch(getTransformedThemeResponsesByFormSuccess(formResponses));
    } catch (err) {
      dispatch(getTransformedThemeResponsesByFormError());
    }
  };
}
