import {
  GET_FORM_RESPONSE_BY_ID_SUCCESS, GET_FORM_RESPONSE_BY_ID_ERROR,
  EDIT_FORM_RESPONSE_SUCCESS, EDIT_FORM_RESPONSE_ERROR,
  EDIT_THEME_BY_ID_SUCCESS, EDIT_THEME_BY_ID_ERROR,
  EDIT_COORDINATES_BY_ID_SUCCESS, EDIT_COORDINATES_BY_ID_ERROR,
  APPROVE_FORM_RESPONSE_SUCCESS, APPROVE_FORM_RESPONSE_ERROR,
  DELETE_FORM_RESPONSE_SUCCESS, DELETE_FORM_RESPONSE_ERROR,
  GET_FORM_RESPONSES_SUCCESS, GET_FORM_RESPONSES_ERROR,
  GET_APPROVED_FORM_RESPONSES_SUCCESS, GET_APPROVED_FORM_RESPONSES_ERROR,
  GET_RESEARCH_SUCCESS, GET_RESEARCH_ERROR, GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_ERROR,
  EDIT_RESEARCH_AND_CAMPAIGN_SUCCESS, EDIT_RESEARCH_AND_CAMPAIGN_ERROR,
  DELETE_RESEARCH_AND_CAMPAIGN_SUCCESS, DELETE_RESEARCH_AND_CAMPAIGN_ERROR,
  ADD_RESEARCH_SUCCESS, ADD_RESEARCH_ERROR, ADD_CAMPAIGN_SUCCESS, ADD_CAMPAIGN_ERROR,
  GET_CURRENT_ADMIN_SUCCESS, GET_CURRENT_ADMIN_ERROR,
  LOGOUT_ADMIN_SUCCESS, LOGOUT_ADMIN_ERROR,
  GET_CONTACT_RESPONSES_SUCCESS, GET_CONTACT_RESPONSES_ERROR,
  DELETE_CONTACT_RESPONSES_SUCCESS, DELETE_CONTACT_RESPONSES_ERROR,
  EDIT_ADMIN_SUCCESS, EDIT_ADMIN_ERROR,
  DELETE_ADMIN_SUCCESS, DELETE_ADMIN_ERROR, ADD_ADMIN_SUCCESS, ADD_ADMIN_ERROR,
  GET_ADMINS_SUCCESS, GET_ADMINS_ERROR,
} from '../actions/admin';

const defaultState = {
  formResponses: null,
  approvedFormResponses: null,
  formResponse: null,
  research: null,
  campaigns: null,
  admin: null,
  admins: [],
  error: false,
  feedbackForms: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_FORM_RESPONSE_BY_ID_SUCCESS: {
      return {
        ...state,
        formResponse: action.formResponse,
        error: false,
      };
    }
    case GET_FORM_RESPONSE_BY_ID_ERROR: {
      return {
        ...state,
        formResponse: null,
        error: true,
      };
    }
    case EDIT_FORM_RESPONSE_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case EDIT_FORM_RESPONSE_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case EDIT_THEME_BY_ID_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case EDIT_THEME_BY_ID_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case EDIT_COORDINATES_BY_ID_SUCCESS: {
      return {
        ...state,
        error: false,
      }
    }
    case EDIT_COORDINATES_BY_ID_ERROR: {
      return {
        ...state,
        error: true,
      }
    }
    case APPROVE_FORM_RESPONSE_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case APPROVE_FORM_RESPONSE_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case DELETE_FORM_RESPONSE_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case DELETE_FORM_RESPONSE_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_FORM_RESPONSES_SUCCESS: {
      return {
        ...state,
        formResponses: action.formResponses,
        error: false,
      };
    }
    case GET_FORM_RESPONSES_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_APPROVED_FORM_RESPONSES_SUCCESS: {
      return {
        ...state,
        formResponses: action.formResponses,
        error: false,
      };
    }
    case GET_APPROVED_FORM_RESPONSES_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_RESEARCH_SUCCESS: {
      return {
        ...state,
        research: action.research,
        error: false,
      };
    }
    case GET_RESEARCH_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        campaigns: action.campaigns,
        error: false,
      };
    }
    case GET_CAMPAIGNS_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case DELETE_RESEARCH_AND_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case DELETE_RESEARCH_AND_CAMPAIGN_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case EDIT_RESEARCH_AND_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case EDIT_RESEARCH_AND_CAMPAIGN_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case ADD_RESEARCH_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case ADD_RESEARCH_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case ADD_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case ADD_CAMPAIGN_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_CURRENT_ADMIN_SUCCESS: {
      return {
        ...state,
        admin: action.admin,
        error: false,
      };
    }
    case GET_CURRENT_ADMIN_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case LOGOUT_ADMIN_SUCCESS: {
      return {
        ...state,
        admin: null,
        error: false,
      };
    }
    case LOGOUT_ADMIN_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_CONTACT_RESPONSES_SUCCESS: {
      return {
        ...state,
        feedbackForms: action.feedbackForms,
        error: false,
      };
    }
    case GET_CONTACT_RESPONSES_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case DELETE_CONTACT_RESPONSES_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case DELETE_CONTACT_RESPONSES_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case EDIT_ADMIN_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case EDIT_ADMIN_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case DELETE_ADMIN_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case ADD_ADMIN_SUCCESS: {
      return {
        ...state,
        error: false,
      };
    }
    case ADD_ADMIN_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_ADMINS_SUCCESS: {
      return {
        ...state,
        admins: action.admins,
        error: false,
      };
    }
    case GET_ADMINS_ERROR: {
      return {
        ...state,
        admins: null,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
};
