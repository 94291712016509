import App, { Container } from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import reduxStoreInjector from '../utils/redux-store-injector';

class _App extends App {
  render() {
    const { Component, pageProps, reduxStore } = this.props;
    return (
      <Container>
        <Provider store={reduxStore}>
          <Component {...pageProps} />
        </Provider>
      </Container>
    );
  }
}

export default reduxStoreInjector(_App);
