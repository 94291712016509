import fetch from 'isomorphic-unfetch';
import actionCreatorFactory from '../utils/action-creator-factory';

import { BACKEND_ENDPOINT } from '../../shared/config';

// action types
export const GET_RESEARCH_SUCCESS = 'GET_RESEARCH_SUCCESS';
export const GET_RESEARCH_ERROR = 'GET_RESEARCH_ERROR';

export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR';

export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_ERROR = 'GET_SERVICES_ERROR';

export const GET_SERVICES_BY_THEME_SUCCESS = 'GET_SERVICES_BY_THEME_SUCCESS';
export const GET_SERVICES_BY_THEME_ERROR = 'GET_SERVICES_BY_THEME_ERROR';

export const GET_CHOSEN_FORM_RESPONSE_SUCCESS = 'GET_CHOSEN_FORM_RESPONSE_SUCCESS';
export const GET_CHOSEN_FORM_RESPONSE_ERROR = 'GET_CHOSEN_FORM_RESPONSE_ERROR';

// action creators
export const getResearchSuccess = actionCreatorFactory(GET_RESEARCH_SUCCESS, 'research');
export const getResearchError = actionCreatorFactory(GET_RESEARCH_ERROR);

export const getCampaignsSuccess = actionCreatorFactory(GET_CAMPAIGNS_SUCCESS, 'campaigns');
export const getCampaignsError = actionCreatorFactory(GET_CAMPAIGNS_ERROR);

export const getServicesSuccess = actionCreatorFactory(GET_SERVICES_SUCCESS, 'services');
export const getServicesError = actionCreatorFactory(GET_SERVICES_ERROR);

export const getServicesByThemeSuccess = actionCreatorFactory(GET_SERVICES_BY_THEME_SUCCESS, 'services');
export const getServicesByThemeError = actionCreatorFactory(GET_SERVICES_BY_THEME_ERROR);

export const getChosenFormResponseSuccess = actionCreatorFactory(GET_CHOSEN_FORM_RESPONSE_SUCCESS, 'formResponse');
export const getChosenFormResponseError = actionCreatorFactory(GET_CHOSEN_FORM_RESPONSE_ERROR);

// action functions
export function getResearch() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/research`);
      let research;
      if (response.status === 200) {
        research = await response.json();
      } else {
        research = null;
      }
      dispatch(getResearchSuccess(research));
    } catch (err) {
      dispatch(getResearchError());
    }
  };
}

export function getCampaigns() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/campaigns`);
      let campaigns;
      if (response.status === 200) {
        campaigns = await response.json();
      } else {
        campaigns = null;
      }
      dispatch(getCampaignsSuccess(campaigns));
    } catch (err) {
      dispatch(getCampaignsError());
    }
  };
}

export function getServices() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/form/Resources/accepted-responses`);
      const services = await response.json();
      dispatch(getServicesSuccess(services));
    } catch (err) {
      dispatch(getServicesError());
    }
  };
}

export function getServicesByTheme(theme) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/form/Resources/${theme}/accepted-responses`);
      const services = await response.json();
      dispatch(getServicesByThemeSuccess(services));
    } catch (err) {
      dispatch(getServicesByThemeError());
    }
  };
}

export function getChosenFormResponse(id) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/${id}/answers`);
      const formResponse = await response.json();
      dispatch(getChosenFormResponseSuccess(formResponse));
    } catch (err) {
      dispatch(getChosenFormResponseError());
    }
  };
}
