import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import admin from './admin';
import l2 from './l2';
import imageCarouselComponent from './imageCarouselComponent';
import researchcampaigns from './researchcampaigns';

const appReducer = combineReducers({
  form: formReducer,
  admin,
  l2,
  imageCarouselComponent,
  researchcampaigns,
});

export default appReducer;
