const APP_NAME = 'ASLA';
const APP_HOST = process.env.HOST || 'http://localhost';
const APP_PORT = process.env.PORT || 8000;
const APP_MODE = process.env.NODE_ENV;
const BACKEND_ENDPOINT = 'https://asla-back.herokuapp.com';

/* Helper Constants */
const IS_PROD = APP_MODE === 'production';
const IS_DEV = APP_MODE === 'development';

export {
  APP_NAME,
  APP_HOST,
  APP_PORT,
  APP_MODE,
  IS_PROD,
  IS_DEV,
  BACKEND_ENDPOINT,
};
