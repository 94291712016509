import fetch from 'isomorphic-unfetch';
import actionCreatorFactory from '../utils/action-creator-factory';

import { BACKEND_ENDPOINT } from '../../shared/config';

// action types
export const GET_FORM_RESPONSE_BY_ID_SUCCESS = 'GET_FORM_RESPONSE_BY_ID_SUCCESS';
export const GET_FORM_RESPONSE_BY_ID_ERROR = 'GET_FORM_RESPONSE_BY_ID_ERROR';

export const EDIT_FORM_RESPONSE_SUCCESS = 'EDIT_FORM_RESPONSE_SUCCESS';
export const EDIT_FORM_RESPONSE_ERROR = 'EDIT_FORM_RESPONSE_ERROR';

export const EDIT_THEME_BY_ID_SUCCESS = 'EDIT_THEME_BY_ID_SUCCESS';
export const EDIT_THEME_BY_ID_ERROR = 'EDIT_THEME_BY_ID_ERROR';

export const EDIT_COORDINATES_BY_ID_SUCCESS = 'EDIT_COORDINATES_BY_ID_SUCCESS';
export const EDIT_COORDINATES_BY_ID_ERROR = 'EDIT_COORDINATES_BY_ID_ERROR';

export const APPROVE_FORM_RESPONSE_SUCCESS = 'APPROVE_FORM_RESPONSE_SUCCESS';
export const APPROVE_FORM_RESPONSE_ERROR = 'APPROVE_FORM_RESPONSE_ERROR';

export const DELETE_FORM_RESPONSE_SUCCESS = 'DELETE_FORM_RESPONSE_SUCCESS';
export const DELETE_FORM_RESPONSE_ERROR = 'DELETE_FORM_RESPONSE_ERROR';

export const GET_FORM_RESPONSES_SUCCESS = 'GET_FORM_RESPONSES_SUCCESS';
export const GET_FORM_RESPONSES_ERROR = 'GET_FORM_RESPONSES_ERROR';

export const GET_APPROVED_FORM_RESPONSES_SUCCESS = 'GET_APPROVED_FORM_RESPONSES_SUCCESS';
export const GET_APPROVED_FORM_RESPONSES_ERROR = 'GET_APPROVED_FORM_RESPONSES_ERROR';

export const GET_RESEARCH_SUCCESS = 'GET_RESEARCH_SUCCESS';
export const GET_RESEARCH_ERROR = 'GET_RESEARCH_ERROR';

export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_ERROR = 'GET_CAMPAIGNS_ERROR';

export const DELETE_RESEARCH_AND_CAMPAIGN_SUCCESS = 'DELETE_RESEARCH_AND_CAMPAIGN_SUCCESS';
export const DELETE_RESEARCH_AND_CAMPAIGN_ERROR = 'DELETE_RESEARCH_AND_CAMPAIGN_ERROR';

export const EDIT_RESEARCH_AND_CAMPAIGN_SUCCESS = 'EDIT_RESEARCH_AND_CAMPAIGN_SUCCESS';
export const EDIT_RESEARCH_AND_CAMPAIGN_ERROR = 'EDIT_RESEARCH_AND_CAMPAIGN_ERROR';

export const ADD_RESEARCH_SUCCESS = 'ADD_RESEARCH_SUCCESS';
export const ADD_RESEARCH_ERROR = 'ADD_RESEARCH_ERROR';

export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_ERROR = 'ADD_CAMPAIGN_ERROR';

export const LOGOUT_ADMIN_SUCCESS = 'LOGOUT_ADMIN_SUCCESS';
export const LOGOUT_ADMIN_ERROR = 'LOGOUT_ADMIN_ERROR';

export const GET_CURRENT_ADMIN_SUCCESS = 'GET_CURRENT_ADMIN_SUCCESS';
export const GET_CURRENT_ADMIN_ERROR = 'GET_CURRENT_ADMIN_ERROR';

export const GET_CONTACT_RESPONSES_SUCCESS = 'GET_CONTACT_RESPONSES_SUCCESS';
export const GET_CONTACT_RESPONSES_ERROR = 'GET_CONTACT_RESPONSES_ERROR';

export const DELETE_CONTACT_RESPONSES_SUCCESS = 'DELETE_CONTACT_RESPONSES_SUCCESS';
export const DELETE_CONTACT_RESPONSES_ERROR = 'DELETE_CONTACT_RESPONSES_ERROR';

export const EDIT_ADMIN_SUCCESS = 'EDIT_ADMIN_SUCCESS';
export const EDIT_ADMIN_ERROR = 'EDIT_ADMIN_ERROR';

export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';

export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_ERROR = 'ADD_ADMIN_ERROR';

export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_ERROR = 'GET_ADMINS_ERROR';


// action creators
export const getFormResponseByIdSuccess = actionCreatorFactory(GET_FORM_RESPONSE_BY_ID_SUCCESS, 'formResponse');
export const getFormResponseByIdError = actionCreatorFactory(GET_FORM_RESPONSE_BY_ID_ERROR);

export const editFormResponseSuccess = actionCreatorFactory(EDIT_FORM_RESPONSE_SUCCESS);
export const editFormResponseError = actionCreatorFactory(EDIT_FORM_RESPONSE_ERROR);

export const editThemeSuccess = actionCreatorFactory(EDIT_THEME_BY_ID_SUCCESS);
export const editThemeError = actionCreatorFactory(EDIT_THEME_BY_ID_ERROR);

export const editCoordinatesSuccess = actionCreatorFactory(EDIT_COORDINATES_BY_ID_SUCCESS);
export const editCoordinatesError = actionCreatorFactory(EDIT_COORDINATES_BY_ID_ERROR);

export const approveFormResponseSuccess = actionCreatorFactory(APPROVE_FORM_RESPONSE_SUCCESS);
export const approveFormResponseError = actionCreatorFactory(APPROVE_FORM_RESPONSE_ERROR);

export const deleteFormResponseSuccess = actionCreatorFactory(DELETE_FORM_RESPONSE_SUCCESS);
export const deleteFormResponseError = actionCreatorFactory(DELETE_FORM_RESPONSE_ERROR);

export const getFormResponsesSuccess = actionCreatorFactory(GET_FORM_RESPONSES_SUCCESS, 'formResponses');
export const getFormResponsesError = actionCreatorFactory(GET_FORM_RESPONSES_ERROR);

export const getApprovedFormResponsesSuccess = actionCreatorFactory(GET_APPROVED_FORM_RESPONSES_SUCCESS, 'formResponses');
export const getApprovedFormResponsesError = actionCreatorFactory(GET_APPROVED_FORM_RESPONSES_ERROR);

export const getResearchSuccess = actionCreatorFactory(GET_RESEARCH_SUCCESS, 'research');
export const getResearchError = actionCreatorFactory(GET_RESEARCH_ERROR);

export const getCampaignsSuccess = actionCreatorFactory(GET_CAMPAIGNS_SUCCESS, 'campaigns');
export const getCampaignsError = actionCreatorFactory(GET_CAMPAIGNS_ERROR);

export const deleteResearchAndCampaignSuccess = actionCreatorFactory(DELETE_RESEARCH_AND_CAMPAIGN_SUCCESS);
export const deleteResearchAndCampaignError = actionCreatorFactory(DELETE_RESEARCH_AND_CAMPAIGN_ERROR);

export const editResearchAndCampaignSuccess = actionCreatorFactory(EDIT_RESEARCH_AND_CAMPAIGN_SUCCESS);
export const editResearchAndCampaignError = actionCreatorFactory(EDIT_RESEARCH_AND_CAMPAIGN_ERROR);

export const addResearchSuccess = actionCreatorFactory(ADD_RESEARCH_SUCCESS, 'response');
export const addResearchError = actionCreatorFactory(ADD_RESEARCH_ERROR);

export const addCampaignSuccess = actionCreatorFactory(ADD_CAMPAIGN_SUCCESS, 'response');
export const addCampaignError = actionCreatorFactory(ADD_CAMPAIGN_ERROR);

export const getCurrentAdminSuccess = actionCreatorFactory(GET_CURRENT_ADMIN_SUCCESS, 'admin');
export const getCurrentAdminError = actionCreatorFactory(GET_CURRENT_ADMIN_ERROR);

export const logoutAdminSuccess = actionCreatorFactory(LOGOUT_ADMIN_SUCCESS);
export const logoutAdminError = actionCreatorFactory(LOGOUT_ADMIN_ERROR);

export const getContactResponsesSuccess = actionCreatorFactory(GET_CONTACT_RESPONSES_SUCCESS, 'feedbackForms');
export const getContactResponsesError = actionCreatorFactory(GET_CONTACT_RESPONSES_ERROR);

export const deleteContactResponseSuccess = actionCreatorFactory(DELETE_CONTACT_RESPONSES_SUCCESS);
export const deleteContactResponseError = actionCreatorFactory(DELETE_CONTACT_RESPONSES_ERROR);

export const editAdminSuccess = actionCreatorFactory(EDIT_ADMIN_SUCCESS);
export const editAdminError = actionCreatorFactory(EDIT_ADMIN_ERROR);

export const deleteAdminSuccess = actionCreatorFactory(DELETE_ADMIN_SUCCESS);
export const deleteAdminError = actionCreatorFactory(DELETE_ADMIN_ERROR);

export const addAdminSuccess = actionCreatorFactory(ADD_ADMIN_SUCCESS);
export const addAdminError = actionCreatorFactory(ADD_ADMIN_ERROR);

export const getAdminsSuccess = actionCreatorFactory(GET_ADMINS_SUCCESS, 'admins');
export const getAdminsError = actionCreatorFactory(GET_ADMINS_ERROR);

// action functions
export function getFormResponseById(id) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/${id}/answers`);
      const formResponse = await response.json();
      dispatch(getFormResponseByIdSuccess(formResponse));
    } catch (err) {
      dispatch(getFormResponseByIdError());
    }
  };
}

export function editFormResponse(id, answers) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/${id}`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ answers }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status === 400) {
        dispatch(editFormResponseError());
      } else {
        dispatch(editFormResponseSuccess());
      }
    } catch (err) {
      dispatch(editFormResponseError());
    }
  };
}

export function editFormResponseTheme(id, theme) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/${id}/editTheme`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ theme }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status === 400) {
        dispatch(editThemeError());
      } else {
        dispatch(editThemeSuccess());
      }
    } catch (err) {
      dispatch(editThemeError());
    }
  };
}

export function editFormResponseCoordinates(id, latitude, longitude) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/${id}/editCoordinates`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({ latitude, longitude }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status === 400) {
        dispatch(editCoordinatesError());
      } else {
        dispatch(editCoordinatesSuccess());
      }
    } catch (err) {
      dispatch(editCoordinatesError());
    }
  };
}

export function approveFormResponse(id) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/${id}/accept`, {
        method: 'PUT',
        credentials: 'include',
      });
      if (response.status === 400 || response.status === 401) {
        dispatch(approveFormResponseError());
      } else {
        dispatch(approveFormResponseSuccess());
      }
    } catch (err) {
      dispatch(approveFormResponseError());
    }
  };
}

export function deleteFormResponse(id) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/${id}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status === 404) {
        dispatch(deleteFormResponseError());
      } else {
        dispatch(deleteFormResponseSuccess());
      }
    } catch (err) {
      dispatch(deleteFormResponseError());
    }
  };
}

export function getFormResponses(formId) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/form/${formId}/nonaccepted-responses`);
      let formResponses;
      if (response.status === 200) {
        formResponses = await response.json();
      } else if (response.status === 404) {
        formResponses = null;
      }
      dispatch(getFormResponsesSuccess(formResponses));
    } catch (err) {
      dispatch(getFormResponsesError());
    }
  };
}

export function getApprovedFormResponses(formId) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/form/${formId}/accepted-responses`);
      let formResponses;
      if (response.status === 200) {
        formResponses = await response.json();
      } else if (response.status === 404) {
        formResponses = null;
      }
      dispatch(getApprovedFormResponsesSuccess(formResponses));
    } catch (err) {
      dispatch(getApprovedFormResponsesError());
    }
  };
}

export function getResearch() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/research`);
      let research;
      if (response.status === 200) {
        research = await response.json();
      } else {
        research = null;
      }
      dispatch(getResearchSuccess(research));
    } catch (err) {
      dispatch(getResearchError());
    }
  };
}

export function getCampaigns() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/campaigns`);
      let campaigns;
      if (response.status === 200) {
        campaigns = await response.json();
      } else {
        campaigns = null;
      }
      dispatch(getCampaignsSuccess(campaigns));
    } catch (err) {
      dispatch(getCampaignsError());
    }
  };
}

export function deleteResearchAndCampaign(id) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/researchAndCampaigns/${id}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status === 404) {
        dispatch(deleteResearchAndCampaignError());
      } else {
        dispatch(deleteResearchAndCampaignSuccess());
      }
    } catch (err) {
      dispatch(deleteResearchAndCampaignError());
    }
  };
}

export function editResearchAndCampaign(id, answers) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/researchAndCampaigns/${id}`, {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(answers),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status === 400) {
        dispatch(editResearchAndCampaignError());
      } else {
        dispatch(editResearchAndCampaignSuccess());
      }
    } catch (err) {
      dispatch(editResearchAndCampaignError());
    }
  };
}


export function addResearch(newResponse) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/research`, {
        method: 'POST',
        dataType: 'JSON',
        credentials: 'include',
        body: JSON.stringify(newResponse),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const entry = await response.json();
      dispatch(addResearchSuccess(entry));
    } catch (err) {
      dispatch(addResearchError());
    }
  };
}

export function addCampaign(newResponse) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/campaign`, {
        method: 'POST',
        dataType: 'JSON',
        credentials: 'include',
        body: JSON.stringify(newResponse),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      const entry = await response.json();
      dispatch(addCampaignSuccess(entry));
    } catch (err) {
      dispatch(addCampaignError());
    }
  };
}

export function getCurrentAdmin() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/admin/me`, {
        credentials: 'include',
      });
      if (response.status === 401) {
        dispatch(getCurrentAdminError());
      } else {
        const admin = await response.json();
        dispatch(getCurrentAdminSuccess(admin));
      }
    } catch (e) {
      dispatch(getCurrentAdminError());
    }
  };
}

export function logoutAdmin() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/logout`, {
        credentials: 'include',
      });
      if (response.status !== 200) {
        dispatch(logoutAdminError());
      } else {
        dispatch(logoutAdminSuccess());
      }
    } catch (e) {
      dispatch(logoutAdminError());
    }
  };
}

export function getContactFormResponses() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/feedbackform`);
      let feedbackForms;
      if (response.status === 200) {
        feedbackForms = await response.json();
      } else {
        feedbackForms = null;
      }
      dispatch(getContactResponsesSuccess(feedbackForms));
    } catch (err) {
      dispatch(getContactResponsesError());
    }
  };
}

export function deleteContactFormResponse(id) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/feedbackform/${id}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status === 200) {
        dispatch(deleteContactResponseSuccess());
      } else {
        dispatch(deleteContactResponseError());
      }
    } catch (err) {
      dispatch(deleteContactResponseError());
    }
  };
}

export function editAdmin(id, profile) {
  return async (dispatch) => {
    try {
      // don't send back null elements
      const strippedProfile = {};
      if (profile.firstName != null) {
        strippedProfile.firstName = profile.firstName;
      }
      if (profile.lastName != null) {
        strippedProfile.lastName = profile.lastName;
      }
      if (profile.email != null) {
        strippedProfile.email = profile.email;
      }
      const response = await fetch(`${BACKEND_ENDPOINT}/api/admin/${id}`, {
        credentials: 'include',
        method: 'PUT',
        body: JSON.stringify(strippedProfile),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status !== 200) {
        dispatch(editAdminError());
      } else {
        dispatch(editAdminSuccess());
      }
    } catch (e) {
      dispatch(editAdminError());
    }
  };
}

export function deleteAdmin(id) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/admin/${id}`, {
        credentials: 'include',
        method: 'DELETE',
      });
      if (response.status !== 200) {
        dispatch(deleteAdminError());
      } else {
        dispatch(deleteAdminSuccess());
      }
    } catch (e) {
      dispatch(deleteAdminError());
    }
  };
}

export function addAdmin(profile) {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/admin`, {
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify(profile),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      });
      if (response.status !== 200) {
        dispatch(addAdminError());
      } else {
        dispatch(addAdminSuccess());
      }
    } catch (e) {
      dispatch(addAdminError());
    }
  };
}

export function getAdmins() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/admin`, {
        credentials: 'include',
      });
      if (response.status !== 200) {
        dispatch(getAdminsError());
      } else {
        const admins = await response.json();
        dispatch(getAdminsSuccess(admins));
      }
    } catch (e) {
      dispatch(getAdminsError());
    }
  };
}
