import fetch from 'isomorphic-unfetch';
import actionCreatorFactory from '../utils/action-creator-factory';

import { BACKEND_ENDPOINT } from '../../shared/config';

// action types
export const GET_RANDOM_FORM_RESPONSES_SUCCESS = 'GET_RANDOM_FORM_RESPONSES_SUCCESS';
export const GET_RANDOM_FORM_RESPONSES_ERROR = 'GET_RANDOM_FORM_RESPONSES_ERROR';


// action creators
export const getRandomFormResponsesSuccess = actionCreatorFactory(GET_RANDOM_FORM_RESPONSES_SUCCESS, 'formResponses');
export const getRandomFormResponsesError = actionCreatorFactory(GET_RANDOM_FORM_RESPONSES_ERROR);


// action functions
export function getRandomResponses() {
  return async (dispatch) => {
    try {
      const response = await fetch(`${BACKEND_ENDPOINT}/api/formresponse/random-responses`);
      const formResponses = await response.json();
      dispatch(getRandomFormResponsesSuccess(formResponses));
    } catch (err) {
      dispatch(getRandomFormResponsesError());
    }
  };
}
