import {
  GET_RESEARCH_SUCCESS, GET_RESEARCH_ERROR,
  GET_CAMPAIGNS_SUCCESS, GET_CAMPAIGNS_ERROR,
  GET_SERVICES_SUCCESS, GET_SERVICES_ERROR,
  GET_CHOSEN_FORM_RESPONSE_SUCCESS, GET_CHOSEN_FORM_RESPONSE_ERROR,
  GET_SERVICES_BY_THEME_SUCCESS, GET_SERVICES_BY_THEME_ERROR,
} from '../actions/researchcampaigns';

const defaultState = {
  research: null,
  campaigns: null,
  services: [],
  error: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_RESEARCH_SUCCESS: {
      return {
        ...state,
        research: action.research,
        error: false,
      };
    }
    case GET_RESEARCH_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        campaigns: action.campaigns,
        error: false,
      };
    }
    case GET_CAMPAIGNS_ERROR: {
      return {
        ...state,
        error: true,
      };
    }
    case GET_SERVICES_SUCCESS: {
      return {
        ...state,
        services: action.services,
        error: false,
      };
    }
    case GET_SERVICES_ERROR: {
      return {
        ...state,
        services: [],
        error: true,
      };
    }
    case GET_SERVICES_BY_THEME_SUCCESS: {
      return {
        ...state,
        services: action.services,
        error: false,
      };
    }
    case GET_SERVICES_BY_THEME_ERROR: {
      return {
        ...state,
        services: [],
        error: true,
      };
    }
    case GET_CHOSEN_FORM_RESPONSE_SUCCESS: {
      return {
        ...state,
        selectedFormData: action.formResponse,
        error: false,
      };
    }
    case GET_CHOSEN_FORM_RESPONSE_ERROR: {
      return {
        ...state,
        selectedFormData: null,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
};
