import {
  GET_RANDOM_FORM_RESPONSES_SUCCESS, GET_RANDOM_FORM_RESPONSES_ERROR,
} from '../actions/imageCarouselComponent';

const defaultState = {
  imagesAndQuotes: [],
  randomResponses: [],
  error: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_RANDOM_FORM_RESPONSES_SUCCESS: {
      return {
        ...state,
        randomResponses: action.formResponses,
        error: false,
      };
    }
    case GET_RANDOM_FORM_RESPONSES_ERROR: {
      return {
        ...state,
        randomResponses: [],
        error: true,
      };
    }
    default: {
      return state;
    }
  }
};
