import {
  GET_CHOSEN_FORM_RESPONSE_SUCCESS, GET_CHOSEN_FORM_RESPONSE_ERROR,
  GET_THEME_RESPONSES_BY_FORM_SUCCESS, GET_THEME_RESPONSES_BY_FORM_ERROR,
  GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_SUCCESS,
  GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_ERROR,
} from '../actions/l2';

const defaultState = {
  currentTheme: null,
  mapData: [], // This holds data to populate the map Component
  selectedFormData: null, // This is the singular form response
  formOptions: {
    'Community Voices': true,
    'Different Futures': false,
  },
  showSideBar: true,
  newThemeSelected: false,
  error: null,
  condensedMapData: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_CHOSEN_FORM_RESPONSE_SUCCESS: {
      return {
        ...state,
        selectedFormData: action.formResponse,
        error: false,
      };
    }
    case GET_CHOSEN_FORM_RESPONSE_ERROR: {
      return {
        ...state,
        selectedFormData: null,
        error: true,
      };
    }
    case GET_THEME_RESPONSES_BY_FORM_SUCCESS: {
      return {
        ...state,
        mapData: action.formResponses,
        error: false,
      };
    }
    case GET_THEME_RESPONSES_BY_FORM_ERROR: {
      return {
        ...state,
        mapData: [],
        error: true,
      };
    }
    case GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_SUCCESS: {
      return {
        ...state,
        condensedMapData: action.formResponses,
        error: false,
      };
    }
    case GET_TRANSFORMED_THEME_RESPONSES_BY_FORM_ERROR: {
      return {
        ...state,
        condensedMapData: null,
        error: true,
      };
    }
    default: {
      return state;
    }
  }
};
